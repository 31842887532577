import { useState, useEffect } from "react"
import Cookies from "js-cookie"
import costPerMile from "../constants/cost_per_mile.json"
import { replaceValue } from "../util/string"
import geo from "../services/geoLocation"

export const useGeoDataEffect = (geoPostDescription?: string) => {
  const sessionCookie = Cookies.get("geoLocation")
  const parsedCookie = sessionCookie ? JSON.parse(sessionCookie) : null
  const [geoDescription, setGeoDescription] = useState("")
  const [geoSessionValue, setGeoSessionValue] = useState(sessionCookie)
  const [geoData, setGeoData] = useState(parsedCookie)

  useEffect(() => {
    const fetchGeoData = async () => {
      if (!geoSessionValue) {
        try {
          const geoDataString = (await geo.getGeoLocation()) as string
          const newGeoData = geoDataString ? JSON.parse(geoDataString) : null
          if (newGeoData && newGeoData.country_code2 === "US") {
            Cookies.set("geoLocation", geoDataString, { expires: 7 })
            setGeoData(newGeoData)
            setGeoSessionValue(geoDataString)
          }
        } catch (error) {
          console.error("Error fetching geolocation", error)
          return
        }
      }

      if (geoData && geoData.country_code2 === "US") {
        const geoCity = geoData.city || ""
        const matchingLocation = costPerMile.find(
          (location) => location.city.toLowerCase() === geoCity.toLowerCase(),
        )
        const cost_per_mile = matchingLocation
          ? matchingLocation.cost_per_mile
          : costPerMile.find(
              (location) => location.city.toLowerCase() === "overall",
            )?.cost_per_mile
        if (geoPostDescription) {
          const updatedDescription = replaceValue(geoPostDescription, {
            location: geoCity,
            cost_per_mile,
          })
          setGeoDescription(updatedDescription)
        }
      }
    }

    fetchGeoData()
  }, [geoPostDescription])

  return { geoDescription, geoSessionValue }
}
