import { FC } from "react"
import Image from "next/image"
import { Anchor } from "../../app/components/anchor"
import classNames from "classnames"

import { readImage } from "../../app/utils/read-image"
import { useReviewsContext } from "../../context/ReviewsContext/ReviewsContextProvider"
import { PremiumHeroForm } from "../../components/premium/hero-form"
import StarRating from "../../components/StarRating"
import Map from "../../assets/vector/brown-map.svg"
import Cursor from "../../assets/vector/brown-cursor.svg"
import Badge from "../../assets/vector/brown-badge.svg"
import Globe from "../../assets/vector/brown-globe.svg"

import BgPaws from "../../assets/images/bg-paws.png"
import { BlokImage } from ".."
import PageNav, { NavigationType } from "./PageNav"

export interface IPetsHeroB {
  blok: {
    tagline: string
    title: string
    description: string
    buttonLink?: string
    buttonLabel?: string
    image?: BlokImage
    smImage?: BlokImage
    navItems?: NavigationType["navItems"]
    categories?: NavigationType["categories"]
    isNavHidden?: boolean
    ctaRoute?: string
    hasNavigation?: boolean
    showBadge?: boolean
    hasBookingGuarantee?: number
    hasSocialProof?: number
    isAnimals?: boolean
  }
}

const PetsHeroB: FC<IPetsHeroB> = (props) => {
  const {
    tagline,
    title,
    description,
    buttonLabel,
    buttonLink,
    image,
    ctaRoute,
    navItems = [],
    categories = [],
    isNavHidden,
    hasNavigation,
    hasBookingGuarantee,
    hasSocialProof = false,
    smImage,
    isAnimals,
  } = props.blok
  const { blokReviews } = useReviewsContext()

  return (
    <>
      {hasNavigation && (
        <PageNav
          noBlok
          route={ctaRoute || buttonLink}
          buttonLabel={buttonLabel}
          navigationData={{ navItems, categories, isNavHidden }}
          position=""
        />
      )}
      <section
        id="pets-hero"
        className={classNames("relative bg-secondary-100", {
          "md:h-[801px]": hasSocialProof,
          "md:h-[685px]": !hasSocialProof,
        })}
      >
        <div>
          {isAnimals && (
            <Image
              src={BgPaws.src}
              layout="fill"
              alt={"pets related page"}
              className="z-0 hidden md:block object-contain md:-mt-4 object-center"
            />
          )}

          <div className="flex justify-center flex-col md:flex-row gap-8 md:gap-6 md:max-lg:h-[500px] md:h-[664px] relative max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto md:p-4 md:pt-10 md:px-8 md:max-lg:px-4">
            <div className="text-black8 font-merriweather w-full md:w-1/2 lg:max-w-[651px] text-left px-6 pt-4">
              {hasSocialProof && (
                <div
                  className="flex font-work_sans mb-6 md:-mt-10"
                  id="reviews"
                >
                  <section className="w-[128px] h-[60px] p-2 rounded-lg shadow-md bg-white mr-2">
                    <Image
                      className="h-[22px] w-auto"
                      src="/icons/google.svg"
                      alt="google logo"
                      width={100}
                      height={20}
                    />

                    <div className="w-full flex flex-row justify-start items-center text-sm">
                      <StarRating value={5} className="w-20" />

                      <Anchor
                        href="https://www.google.com/maps/place/CitizenShipper/@32.2908037,-95.3054722,17z/data=!4m7!3m6!1s0x0:0xaa0dc85bb2e33171!8m2!3d32.2908037!4d-95.3054722!9m1!1b1?hl=en"
                        target="_blank"
                        rel="noreferrer noopener"
                        passHref
                      >
                        {blokReviews?.googleRatings}
                      </Anchor>
                    </div>
                  </section>
                  <section className="w-[128px] h-[60px] p-2 rounded-lg shadow-md bg-white">
                    <Image
                      className="h-[22px] w-auto"
                      src="/icons/trust-pilot.svg"
                      alt="trust pilot logo"
                      width={100}
                      height={20}
                    />

                    <div className="w-full flex flex-row  justify-start items-center text-sm">
                      <StarRating value={5} className="w-20" />

                      <Anchor
                        href="https://www.trustpilot.com/review/citizenshipper.com?stars=5#:~:text=Reply%20from%20CitizenShipper"
                        target="_blank"
                        rel="noreferrer noopener"
                        passHref
                      >
                        {blokReviews?.trustpilotRatings}
                      </Anchor>
                    </div>
                  </section>
                </div>
              )}
              <p className="text-md font-light italic mt-10 lg:mt-20 mb-0">
                {tagline}
              </p>
              <h1 className="text-[42px] leading-[63px] lg:leading-[60px] lg:text-[40px] xl:leading-[68px] xl:text-[44px] font-bold mt-1 md:mt-4 mb-6">
                {title}
              </h1>
              <p className="text-lg mb-0 leading-7 md:leading-9 lg:max-w-lg font-inter text-black8 text-opacity-70">
                {description}
              </p>
              {hasSocialProof && (
                <section id="stats" className="my-12">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 mb-4 max-w-[1350px] mx-auto font-inter text-gray16">
                    <div className="flex items-center">
                      <Image
                        src={Globe.src}
                        alt={"drivers"}
                        height={48}
                        width={48}
                        loading="lazy"
                      />
                      <div className="pl-2">
                        <p className="mb-0.5 font-merriweather">
                          2,500+ drivers
                        </p>
                        <span className="text-sm">
                          Actively competing for your business.
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Image
                        src={Cursor.src}
                        alt={"listings"}
                        height={48}
                        width={48}
                        loading="lazy"
                      />
                      <div className="pl-2">
                        <p className="mb-0.5 font-merriweather">
                          90% of listings
                        </p>
                        <span className="text-sm">
                          Receive a quote in less than 10 min.
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Image
                        src={Map.src}
                        alt={"rating"}
                        height={48}
                        width={48}
                        loading="lazy"
                      />
                      <div className="pl-2">
                        <p className="mb-0.5 font-merriweather">100,000,000+</p>
                        <span className="text-sm">
                          Miles driven by qualified transporters.
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Image
                        src={Badge.src}
                        alt={"rating"}
                        height={48}
                        width={48}
                        loading="lazy"
                      />
                      <div className="pl-2">
                        <p className="mb-0.5 font-merriweather">
                          14+ years experience
                        </p>
                        <span className="text-sm">
                          Shipping what matters most.
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>

            <div className="w-full md:w-1/2 relative lg:w-[492px] h-[428px] flex items-center justify-center p-0 z-20">
              {image && (
                <Image
                  src={readImage(image)}
                  alt={image.alt}
                  width={492}
                  height={428}
                  objectFit="cover"
                  objectPosition="center"
                  className={classNames(
                    "w-[435px] h-[378px] md:w-[492px] md:h-[428px] order-first md:order-last mb-12 md:mb-0 object-cover object-center",
                    smImage?.filename ? "hidden md:block" : "block",
                  )}
                />
              )}
              {smImage?.filename && (
                <Image
                  src={readImage(smImage)}
                  alt={smImage.alt}
                  width="375"
                  height={378}
                  objectFit="contain"
                  objectPosition="center"
                  className={classNames(
                    "w-full h-[378px] order-first mb-12 md:hidden object-cover object-center",
                  )}
                />
              )}
            </div>
          </div>
          <div className="relative lg:absolute lg:-bottom-24 max-w-7xl mx-auto  bg-white rounded-1 md:h-[218px] px-6 md:px-5 lg:px-10 py-4 md:py-11 right-0 left-0 md:border border-gray52 shadow-sm">
            <div className="md:flex justify-center">
              {" "}
              <PremiumHeroForm
                buttonLabel={buttonLabel}
                buttonLink={buttonLink}
              />
            </div>
            {hasBookingGuarantee && (
              <small className="pt-4 flex items-center sm:mr-10 justify-start">
                {" "}
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.1"
                    width="50"
                    height="50"
                    rx="25"
                    fill="#1B53E1"
                  />
                  <path
                    d="M32.63 15.8067L26.2133 13.4034C25.5483 13.1584 24.4633 13.1584 23.7983 13.4034L17.3817 15.8067C16.145 16.2734 15.1417 17.72 15.1417 19.0384V28.4884C15.1417 29.4334 15.76 30.6817 16.5183 31.2417L22.935 36.0367C24.0667 36.8884 25.9217 36.8884 27.0533 36.0367L33.47 31.2417C34.2283 30.67 34.8467 29.4334 34.8467 28.4884V19.0384C34.8583 17.72 33.855 16.2734 32.63 15.8067ZM29.06 22.34L24.0433 27.3567C23.8683 27.5317 23.6467 27.6134 23.425 27.6134C23.2033 27.6134 22.9817 27.5317 22.8067 27.3567L20.94 25.4667C20.6017 25.1284 20.6017 24.5684 20.94 24.23C21.2783 23.8917 21.8383 23.8917 22.1767 24.23L23.4367 25.49L27.835 21.0917C28.1733 20.7534 28.7333 20.7534 29.0717 21.0917C29.41 21.43 29.41 22.0017 29.06 22.34Z"
                    fill="#9F6D25"
                  />
                </svg>
                <span className="inline-block pl-3 text-sm">
                  All transports are covered under our{" "}
                  <span className="text-gold">
                    <Anchor
                      href="https://help.citizenshipper.com/hc/en-us/articles/360041203251-CitizenShipper-Booking-Assurance-Guarantee"
                      className="text-gold hover:underline"
                      newTab
                    >
                      Booking Assurance Guarantee.
                    </Anchor>
                  </span>
                </span>
              </small>
            )}
          </div>
        </div>
      </section>
      <section className="lg:mb-10 md:py-12 md:mb-12" />
    </>
  )
}
export default PetsHeroB
